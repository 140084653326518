@import '../../../../styles/media.scss';

.footer {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;

  @include mediaL {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }
}

.footerPrivacy {
  display: none;

  @include mediaL {
    display: flex;
    justify-content: space-between;
    max-width: 570px;
    width: 100%;
  }
}
